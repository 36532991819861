import { createRouter, createWebHistory } from 'vue-router';

import store from '../store';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
    meta: { layout: 'auth', sidebar: false, footer: false }
  },
  {
    path: '/incident',
    name: 'incident',
    component: () => import('../views/incident.vue'),
    meta: { layout: 'app', sidebar: true, footer: false }
  },
  {
    path: '/map',
    name: 'map',
    component: () => import('../views/map.vue'),
    meta: { layout: 'app', sidebar: true, footer: false }
  },
  {
    path: '/adminmanagement',
    name: 'adminmanagement',
    component: () => import('../views/adminmanagement.vue'),
    meta: { layout: 'app', sidebar: true, footer: false }
  },
  {
    path: '/report',
    name: 'report',
    meta: { layout: 'app', sidebar: true, footer: false },
    children: [
      {
        path: 'incidentrpt',
        name: '/report/incidentrpt',
        component: () => import('../views/report/report_view.vue'),
        meta: { layout: 'app', sidebar: true, footer: false }
      }
    ]
  },
  {
    path: '/:catchAll(.*)', // Wildcard route to catch all unknown paths
    redirect: '/incident'
  }
];

const router = new createRouter({
  // mode: 'history',
  history: createWebHistory(),
  linkExactActiveClass: 'active',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  const publicPages = ['login', 'map'];
  const authRequired = !publicPages.includes(to.name);
  // const authRequired = false;
  const loggedIn = localStorage.getItem('member');
  // const memberData = JSON.parse(loggedIn); // comment
  const token = localStorage.getItem('token');
  // const { authorize } = to.meta; // comment

  if (authRequired && !loggedIn && !token) {
    return next('/login');
  }

  if (to.path === '/') {
    return next('/incident');
  }

  if (to.meta && to.meta.layout && to.meta.layout === 'auth') {
    store.commit('setLayout', 'auth');
  } else {
    store.commit('setLayout', 'app');
  }

  next();
});

export default router;
